import {takeLatest, call, put, takeEvery} from 'redux-saga/effects'
import { loginStart } from '../../api/authAPI';
import { getCourses } from '../../api/courseAPI';
import { getAllExercises } from '../../api/exerciseAPI';
import { getUser, updateLearningProgress, updatePracticeProgress, updatePracticeRecord } from '../../api/userAPI';
import * as userAction from '../actions/user'
import { getCoursesFailure, getCoursesSuccess } from '../actions/courses';
import { getExercisesFailure, getExercisesSuccess } from '../actions/exercises';
import { updateLearningProgressSuccess, updatePracticeProgressFailure } from '../actions/user';


const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
}

const updateUser = (userUpdated) => {
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem("user", JSON.stringify({accessToken: user.accessToken, ...userUpdated}));
}

function* loginRequestSaga(action){
    try {    
        
        const user = yield call(loginStart, action.payload)
        

        setUser(user.data)

        yield put(userAction.loginSuccess(user.data))
        
    } catch (err){
        yield put(userAction.loginFailure(err.response.data))
    }
}
        
function* updatePracticeRequestSaga(action){

    try {    
        
        const userUpdated = yield call(updatePracticeProgress, action.payload)
        yield call(updateUser, userUpdated.data)
        yield put(userAction.updatePracticeProgressSuccess(userUpdated.data))
        
    } catch (err){
        yield put(updatePracticeProgressFailure())
    }
}

function* updatePracticeRecordSaga(action){

    try {    
        
        const userUpdated = yield call(updatePracticeRecord, action.payload)
        yield call(updateUser, userUpdated.data)
        yield put(userAction.updatePracticeRecordSuccess(userUpdated.data))
        
    } catch (err){
        yield put(updatePracticeProgressFailure())
    }
}

function* updateLearningRequestSaga(action){

    try{
        const userUpdated = yield call(updateLearningProgress, action.payload)
        yield call(updateUser, userUpdated.data)
        yield put(userAction.updatePracticeRecordSuccess(userUpdated.data))
    } catch (err){
        yield put(userAction.updateLearningProgressFailure())
    }
}

function* getExercisesSaga(action){
    try {
        const exercises = yield call(getAllExercises,action.payload)
        yield put(getExercisesSuccess(exercises.data))
    } catch(err){
        yield put(getExercisesFailure(err))
    }
}

function* getExercisesNonUerSaga(){
    try {
        const exercises = yield call(getAllExercises)
        yield put(getExercisesSuccess(exercises.data))
    } catch(err){
        yield put(getExercisesFailure(err))
    }
}

function* getCoursesSaga(){
    try {
        const courses = yield call(getCourses)
        yield put(getCoursesSuccess(courses.data))
    } catch(err){
        yield put(getCoursesFailure())
    }
}


function* mySaga(){

    yield takeLatest("LOGIN_REQUEST",loginRequestSaga)
    yield takeLatest("UPDATE_PRACTICE_PROGRESS_REQUEST",updatePracticeRequestSaga)
    yield takeLatest("UPDATE_LEARNING_PROGRESS_REQUEST",updateLearningRequestSaga)
    yield takeLatest("UPDATE_PRACTICE_RECORD_REQUEST",updatePracticeRecordSaga)
    yield takeLatest("GET_EXERCISES_REQUEST",getExercisesSaga)
    yield takeLatest("GET_EXERCISES_REQUEST_NON_USER",getExercisesNonUerSaga)
    yield takeLatest("GET_COURSES_REQUEST",getCoursesSaga)
}

export default mySaga