import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import "./fireAnt.css"

import styles from "./widget.module.scss"
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getNewUsers } from '../../api/userAPI';
import Avatar from '../Avatar/Avatar';

const Widget = () => {

    const user = useSelector(state => state.user.user)
    const exercises = useSelector(state => state.exercises.exercises)
    const courses = useSelector(state => state.courses.courses)
    const [practiceProgress, setPracticeProgress] = useState(0)
    const [correctAnswerRate, setCorrectAnswerRate] = useState(0)
    const [courseProgress, setCourseProgress] = useState(0)
    const [newUsers, setNewUsers] = useState([])
    const [newUsersLoading, setNewUsersLoading] = useState(true)

    const location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split("/");

    const FireAnt = window.FireAnt

    
    useEffect(() => {
        if(user && exercises){
            let correctAnswer = 0
            if(user.practiceRecord.length === 0){
                setCorrectAnswerRate(0)
                setPracticeProgress(0)
            } else {
                let percentage 
                if(exercises.length!==0){
                    percentage = user.progress.practice/exercises.length * 100
                }else{
                    percentage = 0
                }
                setPracticeProgress(parseFloat (percentage.toFixed(1)))

                for(let i = 0 ; i < user.practiceRecord.length; i++ ) {
                    if(user.practiceRecord[i].result === true){
                        correctAnswer += 1
                    }
                }
                setCorrectAnswerRate(parseFloat ((correctAnswer/user.practiceRecord.length* 100).toFixed(1)))
            }
        }
    },[user, exercises])

    useEffect(() => {
        if(user && courses){
            let courseCount = 0
            for(let i = 0 ; i < courses.length; i++){
                if(user.courseRecord.includes(courses[i]._id)){
                    courseCount ++
                }
            }
            if(courses.length !== 0){
                setCourseProgress(parseFloat ((courseCount/courses.length* 100).toFixed(1)))
            }else{
                setCourseProgress(0)
            }
        }else{
            setCourseProgress((0))
        }
    },[courses, user])

    useEffect(() => {
        getNewUsers()
            .then(res => {
                setNewUsers(res.data)
                setNewUsersLoading(false)
            })
    },[])

    useEffect(() => {
        if(splitLocation[1] === "posts"){
            new FireAnt.MarketsWidget({
                "container_id": "fan-quote-2",
                "locale": "vi",
                "price_line_color": "#71BDDF",
                "grid_color": "#999999",
                "label_color": "#999999",
                "width": "100%",
                "height": "300px"
            })
        }
    },[])
    

    return (
        <div className={styles.widget}>
            {
                (splitLocation[1] !== "posts")
                &&
                (
                user
                ?
                <div className={styles.status}>
                    <div className={styles.text}>Tiến trình học tập của bạn</div>
                    <div className={styles.record}>
                        <div className={styles.practiceRecord}>
                     
                            <div style={{width:`${practiceProgress}%`}} className={styles.userPractice}></div>
                            <div className={styles.recordTitle}>
                                <span>
                                    Thực hành
                                </span>
                            </div>
                            <span className={styles.percentage}>
                                {practiceProgress}%
                            </span>
                        </div>

                        <div className={styles.practiceRateRecord}>
                            <div style={{width:`${correctAnswerRate}%`}} className={styles.userPractice}></div>
                            <div className={styles.recordTitle}>
                                <span>
                                    Tỷ lệ đúng
                                </span>
                            </div>
                            <span className={styles.percentage}>
                                {correctAnswerRate}%
                            </span>
                        </div>

                        <div className={styles.courseRecord}>
                            <div style={{width:`${courseProgress}%`}} className={styles.userPractice}></div>
                            <div className={styles.recordTitle}>
                                <span>
                                    Lý thuyết
                                </span>
                            </div>
                            <span className={styles.percentage}>
                                {courseProgress}%
                            </span>
                        </div>
                        
                    </div>
                </div>
                :

                <div className={styles.status}>
                    <div className={styles.text}>Tiến trình học tập của bạn</div>
                    <div className= {styles.suggestion}>Hãy đăng nhập để mở khóa toàn bộ câu hỏi và theo dõi quá trình học tập của bạn</div> 
                </div>
                )
            
            }

        
            <div id="fan-quote-2" ></div>
        
            
            <div className={styles.contact}>
                <div className={styles.text}>
                    Liên hệ tư vấn
                </div>
                <div className={styles.contactInfo}>
                    <a href='https://openaccount.vps.com.vn/?MKTID=bcfx'>
                        <span>
                            Mở tài khoản chứng khoán tại VPS và nhận được sự tư vấn từ chúng tôi
                        </span>
                    </a>
                </div>

                <div className={styles.contactInfo}>
                    <a href = "https://zalo.me/g/ofozim302">
                        <span>
                            Tham gia nhóm cộng đồng
                        </span>
                    </a>
                </div>

                <div className={styles.contactInfo}>
                    <a href="https://zalo.me/0339570042">
                        <span>
                            Liên hệ cá nhân
                        </span>
                    </a>
                </div>
            </div>

            <div className={styles.greeting}>
                <div className={styles.text}>
                    Chào mừng các thành viên mới:
                </div>

                {
                    !newUsersLoading
                    ?
                    <div className={styles.newUsers}>
                    {
                        newUsers
                        &&
                        newUsers.map((newUser) => 
                            <div className={styles.newUser}>
                                <div className={styles.avatar}>
                                    <Avatar user = {newUser}/>
                                                                        
                                </div>
                                <div className={styles.newUserName}>
                                    <span>{newUser.lastName}</span> <span>{newUser.firstName}</span>
                                </div>
                            </div>
                        )
                    }
                    
                    </div>
                    :
                    <div className={styles.newUsers}>
                    {
                        [...Array(3)].map(() => 
                            <div className={styles.newUser}>
                                <div className={styles.avatar}>
                                    <div className={[styles.avatarSkeleton, "skeleton"].join(" ")}></div>                                    
                                </div>
                                <div className={styles.newUserNameSkeleton + " skeleton"}>
                                    
                                </div>
                            </div>
                        )
                    }
                    
                    </div>
                }


                <div className={styles.text}>
                    đã tham gia vào cộng đồng Chartify
                </div>
            </div>

    </div>
    );
}

export default Widget;
