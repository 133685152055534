import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../../components/NavBar/NavBar';
import Post from '../../components/Post/Post';
import Widget from '../../components/Widget/Widget';
import { Helmet } from 'react-helmet';
import { getPost } from '../../api/postAPI';

import styles from "./posts.module.scss"
import FilterListIcon from '@mui/icons-material/FilterList';

const Posts = ({socket}) => {

    const cre = useRef(null)
    const overlay = useRef(null)

    const [postList, setPostList] = useState([])
    const [posts, setPosts] = useState([])
    const [creteria, setCreteria] = useState("Tất cả")
    const [openFilterCreteria, setOpenFilterCreteria] = useState(false)

    useEffect( () => {
        const fetch = async() => {
            const res = await getPost()
            setPostList(res.data)
            setPosts(res.data)
        }
        fetch()
    },[])

    useEffect(() => {
        function handleClickOutside(event) {
				if (cre.current && !cre.current.contains(event.target) && event.target !== overlay.current) {
					setOpenFilterCreteria(false);
				}
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [cre]);


    if(!postList) {
        return (
            <Navbar></Navbar>
        )
    }
                                                                                                                                                                                                                                                          
    return (
        <div>
            <Navbar />

            <div className={styles.container}>
                <div className={styles.postContainer}>
                    <div className = {styles.filter}>
                        <div className={styles.filterIconContainer} >
                            <div className = {styles.creWrapper} >
                                <span>{creteria}</span>
                                <FilterListIcon />
                                <div ref = {overlay} className = {styles.overlay} onClick = {() => setOpenFilterCreteria(pre => !pre)}></div>
                            </div>

                            {
                                openFilterCreteria
                                &&
                                <div ref = {cre} className = {styles.creterion}>
                                    <div className = {styles.creItem} onClick={() => {
                                        setPosts(postList)
                                        setCreteria("Tất cả")
                                        setOpenFilterCreteria(false)
                                    }}>Tất cả</div>
                                    <div className = {styles.creItem} onClick={() => {
                                        setPosts(postList.filter(post => post.category === "evaluate"))
                                        setCreteria("Nhận định thị trường")
                                        setOpenFilterCreteria(false)
                                    }}>Nhận định thị trường</div>
                                    <div className = {styles.creItem} onClick={() => {
                                        setPosts(postList.filter(post => post.category === "share"))
                                        setCreteria("Chuyên gia phân tích")
                                        setOpenFilterCreteria(false)
                                    }}>Chuyên gia phân tích</div>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        [...posts].map((post, i) => 
                            <Post post = {post} key = {i}/>
                        )
                    }
                </div>
              
                <div className={styles.widgetWrapper}>
                    <Widget />
                </div>
                
            </div>

        </div>
    );
}

export default Posts;
