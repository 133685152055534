import React, { useEffect, useState } from 'react';
import {useSelector} from "react-redux"
import { Link } from 'react-router-dom';
import { getAllExercises } from '../../api/exerciseAPI';
import CommentSection from '../../components/CommentSection/CommentSection';
import Navbar from '../../components/NavBar/NavBar';
import Widget from '../../components/Widget/Widget';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import styles from "./practice.module.scss"

const Practice = () => {

    const user =  useSelector( state => state.user.user)
    const exercises =  useSelector( state => state.exercises.exercises)
    const [loading, setLoading] = useState(true)

    const [exerciseList, setExerciseList] = useState([])
    const [displayExercise, setDisplayExercise] = useState([])
    const [page, setPage] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [openPageList, setOpenPageList] = useState(false)
    const [pageLeftExceeded, setPageLeftExceeded] = useState(false)
    const [pageRightExceeded, setPageRightExceeded] = useState(false)

    useEffect(() => {

        if(exercises.length !== 0){
            setLoading(false)
        }

        if(exercises && user){
            if(user.progress.practice < 18){
                setExerciseList(exercises.slice(0,18))
            }else{
                setExerciseList(exercises.slice(0,user.progress.practice + 1))
            }
        }

        if(!user){
            setExerciseList(exercises.slice(0,18))
        }
    },[exercises, user])

    useEffect(() => {
        if(exerciseList){
            setPageCount(Math.ceil(exerciseList.length/9))
        }
    },[exerciseList])

    useEffect(() => {

        if(exerciseList){
            const list = exerciseList.slice(page*9, (page+1)*9)
            setDisplayExercise([...list])
        }
    }, [page, exerciseList])


    useEffect(() => {
        if(page === 0){
            setPageLeftExceeded(true)
        }else{
            setPageLeftExceeded(false)
        }
        if(page === pageCount - 1){
            setPageRightExceeded(true)
        }else{
            setPageRightExceeded(false)
        }
    }, [page, pageCount])
   
    if(!displayExercise){
        return(
            <Navbar />
        )
    }
    

    return (
        <div className={styles.container}>
            <Navbar />
            
            <div className={styles.exerciseContainer}>
                <div className={styles.intro}>
                    <h2>Bài tập luyện tập</h2>
                    <span>Hãy áp dụng lý thuyết đã học để đưa ra dự đoán chính xác trong các trường hợp sau đây, các bài tập sẽ được mở khóa dần trong quá trình luyện tập:</span>
                </div>

                {
                    !loading
                    ?
                    <>
                        <div className={styles.listWrapper}>
                            <div className={styles.listContainer}>
                                {
                                    displayExercise.map((exercise) => 
                                    <Link to = {`/practice/exercise/${exercise.order}`} state={{ order: exercise.order }}>
                                        <div className={styles.listItem}>
                                            <img src = {exercise.imageURL}></img>
                                            <div className={styles.courseTitle}>
                                                <span>
                                                    {exercise.question}
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                    
                                    )
                                }
                            </div>
                        </div>

                        <div className={styles.pageControl}>
                            {
                                !pageLeftExceeded
                                ?
                                <div className={styles.pageAction} onClick={() => {
                                    setPage(pre => pre - 1)
                                    setOpenPageList(false)
                                }}>Trang trước</div>
                                :
                                <div className={styles.pageActionDisabled}>Trang trước</div>

                            }
                            <div className={styles.currentPage} >
                                <div>{page + 1}</div>
                                
                                {
                                openPageList 
                                && 
                                <div className={styles.pageList}>
                                    {
                                    pageCount
                                    &&
                                    [...Array(pageCount)].map( (pageNumber, i) => (
                                        <div className={ (i === page) ? styles.pageNumberSelected : styles.pageNumber} onClick={() => {
                                            setPage(i)
                                            setOpenPageList(false)
                                        }}>{i+1}</div>
                                    ))
                                    }
                                </div>
                                }
                            </div>
                            {
                                !openPageList && <ArrowDropUpIcon className={styles.arrow} onClick={() => setOpenPageList(!openPageList)}/>
                            }
                            
                            {
                                openPageList && <ArrowDropDownIcon className={styles.arrow} onClick={() => setOpenPageList(!openPageList)}/>
                            }


                            {
                                !pageRightExceeded
                                ?
                                <div className={styles.pageAction} onClick={() => {
                                    setPage(pre => pre + 1)
                                    setOpenPageList(false)
                                }}>Trang sau</div>
                                :
                                <div className={styles.pageActionDisabled}>Trang sau</div>
                            }
                        </div>
                    </>
                    :
                    <>
                    
                        <div className={styles.listWrapper}>
                            <div className={styles.listContainer}>
                                {
                                    [...Array(9)].map((exercise) => 
                                    
                                        <div className={[styles.listItem].join(" ")}>
                                            <div className = {[styles.imgSkeleton, "skeleton"].join(" ")}>

                                            </div>

                                            <div className={styles.courseTitle}>
                                                <div className = {[styles.textSke, "skeleton"].join(" ")}></div>
                                                <div className = {[styles.textSke, "skeleton"].join(" ")}></div>
                                            </div>
                                        </div>

                                    
                                    )
                                }
                            </div>
                        </div>
                        <div className={styles.pageControl}>
                        <div className={styles.pageAction}>Trang trước</div>
                            <div className={styles.currentPage} >
                                <div>{page + 1}</div>
                                
                                {
                                openPageList 
                                && 
                                <div className={styles.pageList}>
                                    0
                                </div>
                                }
                            </div>
                            {
                                !openPageList && <ArrowDropUpIcon className={styles.arrow} onClick={() => setOpenPageList(!openPageList)}/>
                            }
                            
                            {
                                openPageList && <ArrowDropDownIcon className={styles.arrow} onClick={() => setOpenPageList(!openPageList)}/>
                            }

                            <div className={styles.pageAction}>Trang sau</div>
                        </div>
                    </>
                }
                
            </div>

            <div className={styles.widgetWrapper}>
                <Widget />
            </div>
            
        </div>
    );
}

export default Practice;
