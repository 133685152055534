import React, { useState } from 'react';
import style from "./forgotPassword.module.scss"

import { verifyChangePasswordOtp, verifyEmail } from '../../api/authAPI';
import ChangePassword from '../ChangePassword/ChangePassword';

const ForgotPassword = () => {

    const [emailVerification, setEmailVerification] = useState("")
    const [checkEmail, setCheckEmail] = useState(false)
    const [emailVerified, setEmailVerified] = useState(false)
    const [email, setEmail] = useState(false)
    const [verifyCode, setVerifyCode] = useState("")
    const [wrongOtp, setWrongOtp] = useState(false)
    const [otpExpired, setOtpExpired] = useState(false)
    const [verifiedOtp, setVerifiedOtp] = useState(false)
    const [emailNotFound, setEmailNotFound] = useState(false)
    const [verifyEmailLoading, setVerifyEmailLoading] = useState(false)

    const handleEmail = (email) => {
        let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

        if (re.test(email)) {
            setEmailVerification(email);
            setCheckEmail(true);
        } else {
            setCheckEmail(false);
        }
    };

    const handleVerify = () => {
        verifyChangePasswordOtp({
            email,
            code: verifyCode
        })
            .then(res => {
                if(res.data === "correct"){
                    setVerifiedOtp(true)
                }else{
                    setWrongOtp(true)
                }
            })
            .catch(err => {
                if(err.response.data === "expired"){
                    setOtpExpired(true)
                }
            })
    }

    if(verifiedOtp){
        return (
            <ChangePassword email = {email}/>
        )
    }

    if(emailVerified){
        return(
            <div className={style.container2}>

                <div className={style.verifyContainer}>
                        <img src="/mailpt.svg" alt="" />
                        <p>
                            Chúng tôi đã gửi mã xác nhận đến địa chỉ email <span>{email}</span>. Vui lòng nhập mã để thay đổi mật khẩu. Mã xác minh sẽ hết hạn trong 2 phút
                        </p>
                        <input 
                            type = "text"
                            placeholder="Nhập mã"
                            onChange={e => {
                                setVerifyCode(e.target.value)
                                setWrongOtp(false)
                            }}
                        />

                        <div className={style.verifyButton}
                            onClick = {() => {
                                handleVerify()
                                // setLoading(true)
                            }}
                        >
                            Xác minh
                        </div>

                        {
                            wrongOtp && <span className={style.wrongOtp}>Nhập sai mã xác minh</span>
                        }
                        {
                            otpExpired && <span className={style.wrongOtp}>Mã xác minh đã hết hạn</span>
                        }
                        {/* {(loading) && <img src = '/loading.gif' className={style.loading}></img>} */}
                    </div>
            </div>
        )
    }

    return (
        <div className={style.login}>

        <div className={style.container}>
            
            <div className={style.logo}>
                <img src="/final1.png" alt="" />
            </div>
            <div className={style.inputWrapper}>
                <input
                    type="email"
                    placeholder="Nhập địa chỉ email của bạn"
                    onChange={(e) => {
                        handleEmail(e.target.value);
                        setEmailNotFound(false)
                    }}
                />
            </div>

            {!checkEmail ?
            (
                <div
                    className={style.loginButtonDisabled}
                    onClick={(e) => e.preventDefault()}
                >
                    <b>Gửi mã xác minh</b>
                </div>
            ) : (
                <button
                    className={style.loginButton}
                    onClick={() => {
                        setVerifyEmailLoading(true)
                        verifyEmail({email: emailVerification})
                            .then(res => {
                               
                                setEmailVerified(true)
                                setEmail(res.data.email)
                                setVerifyEmailLoading(false)
                            })
                            .catch((err) => {
                                if(err.response.data == "email not found"){
                                    setEmailNotFound(true)
                                    setVerifyEmailLoading(false)
                                }
                            })
                    }}
                >
                    <b>Gửi mã xác minh</b>
                </button>
            )}
            {
                emailNotFound && <span className={style.emailError}>Địa chỉ email chưa được đăng ký</span>
            }
            {(verifyEmailLoading) && <img src = '/loading.gif' className={style.loading}></img>}
        </div>
    </div>		
    );
}

export default ForgotPassword;
