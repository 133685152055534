
import styles from "./courseList.module.scss"
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import FlagIcon from '@mui/icons-material/Flag';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import React, { useEffect, useState } from 'react';
import Navbar from "../../components/NavBar/NavBar";
import { Link,Routes, Route, Outlet, useParams, } from "react-router-dom";
import Widget from "../../components/Widget/Widget";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesRequest } from "../../redux/actions/courses";
import { updateLearningProgress } from "../../api/userAPI";
import { updateLearningProgressRequest } from "../../redux/actions/user";
import SearchIcon from '@mui/icons-material/Search';
import Course from "../Course/Course";

const CourseList = () => {
    const params = useParams()
    const user = useSelector(state => state.user.user)
    const courses = useSelector(state => state.courses.courses)
    const dispatch = useDispatch()
    const [courseList, setCourseList] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        dispatch(getCoursesRequest())        
    },[dispatch])
    
    useEffect(() => {
        if(courses.length !== 0){
            setLoading(false)
        }
        if(courses){
            setCourseList(courses)
            
        }
    }, [courses])

    let creteria = ["Tất cả", "mua", "bán", "đã đọc", "chưa đọc"]

    const [filter, setFilter] = useState(1)    
                                                                    

    if(!courses){
        return (
            <Navbar />
        )
    }

    if(params.id){
        return (
            <>
                <Routes>
                    <Route path=":id/*" element={<Course />} />
                </Routes>
            </>
        )
    }

    return (
        <div className = {styles.container}>
            <Navbar/>

            <div className= {styles.courseContainer}>
                <div className={styles.intro}>
                    <span>Lý thuyết cơ bản</span>                                             
                    <p>Trong thực tế, hãy áp dụng các lý thuyết dưới đây về những nguyên tắc kỹ thuật của cổ phiếu, kết hợp với phân tích cơ bản để đưa ra quyết định tốt nhất nhé!</p>
                </div>

                <div className={styles.filter}>
                    {
                        creteria.map( (cre, i) => 
                            
                            <div className={filter !== i+1 ? styles.creterion : styles.selected} onClick = {() => {
                                setFilter(i+1)
                                if(cre === "Tất cả")
                                    setCourseList(courses)
                                if(cre === "mua")
                                    setCourseList(courses.filter(course => course.category === "buy"))
                                if(cre === "bán")
                                    setCourseList(courses.filter(course => course.category === "sell"))
                                if(cre === "đã đọc")
                                    setCourseList(courses.filter(course => user.courseRecord.includes(course._id)))
                                if(cre === "chưa đọc")
                                    setCourseList(courses.filter(course => !user.courseRecord.includes(course._id)))
                            }} >
                                <span>{cre}</span>
                            </div>
                        )
                    }

                    <div className={styles.search}>
                        <div className = {styles.searchContainer}>
                            <SearchIcon className = {styles.icon}/>
                            <input type="text" placeholder="Nhập từ khóa..." onChange = {(e) => {
                    
                                setCourseList(courses.filter(course => course.title.toUpperCase().includes(e.target.value.toUpperCase().trim())))
                            }}/>
                        </div>
                    </div>
                </div>
                {
                    !loading
                    ?
                    <div className={styles.listContainer}>
                    {
                        courseList && courseList.map((course) => 
                        <Link to = {"" + course._id  + "/" + course.title.trim().replace(/\s+/g, '-').toLowerCase()}>
                            <div className={styles.listItem}>
                                <img src = {course.thumnailImg}></img>
                                <div className={styles.courseTitle}>
                                    <span>
                                        {course.title}
                                    </span>
                                </div>
                                <div className = {styles.courseDetail}>
                                    <div className={styles.title}>
                                        <span>
                                            {course.title}
                                        </span>
                                    </div>
                                    <div className = {styles.des}>
                                        {course.des}
                                    </div>
                                </div>
                            </div>
                        </Link>
                        
                        )
                    }
                    </div>
                    :
                    <div className={styles.listContainer}>
                        {
                            [...Array(12)].map((course) => 
                                <div className={styles.listItem}>
                                    <div className={[styles.imgSkeleton, "skeleton"].join(" ")}>

                                    </div>
                                    <div className={styles.courseTitle}>
                                        <div className = {[styles.textSke, "skeleton"].join(" ")}></div>
                                        <div className = {[styles.textSke, "skeleton"].join(" ")}></div>
                                    </div>
                                    <div className = {styles.courseDetail}>
                                        <div className={styles.titleLoading}>
                                            <div className={[styles.titleSke, "skeleton"].join(" ")}></div>
                                            <div className={[styles.titleSke, "skeleton"].join(" ")}></div>
                                        </div>
                                        <div className={[styles.desLoading, "skeleton"].join(" ")}>

                                        </div>
                                    </div>
                                </div>
                            
                            )
                        }
                    </div>
                }


            </div>
            <div className= {styles.widgetWrapper}>
                <Widget />
            </div>
        </div>
);
}

export default CourseList;
