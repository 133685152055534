import styles from "./home.module.scss"
import "./loading.css"

import Navbar from "../../components/NavBar/NavBar";
// import image from "public/homept.png"

import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Home = () => {
    const user = useSelector(state => state.user.user)
    let practiceProgress;
    user
        ? (practiceProgress = user.progress.practice + 1)
        : (practiceProgress = 1);
    const [loading, setLoading] = useState(true);

    if(loading === true) {
        return(
        <div className="loading-page">
            <img className={styles.pcImage} src="/homept.png" onLoad={() => setLoading(false)} style={{ display:"none" }} ></img>
           
              <div className="spinner-container">
                    <div className="spinner">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>

              </div>
            
          </div> 
        )
    }


    return (
        <div className={styles.container} style={{ display: loading && "none" }}>
            <Navbar />
            <img className={styles.pcImage} src="/homept.png"></img>
            <img className={styles.mobileImage} src="/homefinal.png" ></img>
            <div className={styles.text}>
                <span className={styles.span1}>Chào mừng đến với</span>
                <span className={styles.span2}>CHARTIFY</span>
                <span className={styles.span3}>Hãy luyện tập khả năng phân tích biểu đồ cùng chúng tôi</span>
                <Link to={`/practice/exercise/${practiceProgress}`}>
                    <div className={styles.startButton}>
                        Luyện tập ngay
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default Home;
