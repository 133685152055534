import React, { useState } from 'react';
import style from "./changePassword.module.scss"
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { changePassword } from '../../api/authAPI';
import { useNavigate } from 'react-router-dom';


const ChangePassword = ({email}) => {
    const user = useSelector((state) => state.user);
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [password, setPassword] = useState(""); 
    const [toggleLoading, setToggleLoading] = useState(false);
	const [checkPassword, setCheckPassword] = useState(true)

    const navigate = useNavigate()

	useEffect(() => {
		setCheckPassword(true)
	},[password, passwordConfirm])

    return (
        <div className={style.login}>

            <div className={style.container}>
                
				<div className={style.logo}>
					<img src="/final1.png" alt="" />
				</div>
				<div className={style.inputWrapper}>
					<input
						type="password"
						placeholder="Nhập mật khẩu mới"
						onChange={(e) => {
							setPassword(e.target.value);
							user.error = false;
						}}
					/>
				</div>
				<div className={style.inputWrapper}>
					<input
						type="password"
						placeholder="Nhập lại mật khẩu mới"
						onChange={(e) => {
							setPasswordConfirm(e.target.value);
							user.error = false;
						}}
					/>
				</div>
				{!passwordConfirm || !password ? (
					<div
						className={style.loginButtonDisabled}
						onClick={(e) => e.preventDefault()}
					>
						<b>Xác nhận</b>
					</div>
				) : (
					<button
						className={style.loginButton}
						onClick = {() => {
							setToggleLoading(true)
							if(password !== passwordConfirm){
								setCheckPassword(false)
								setToggleLoading(false)
							} else {
								setToggleLoading(true)
								changePassword({
									email,
									password
								})
									.then(() => {
										setToggleLoading(false)
										window.location.reload()
									})
							}
						}}
					>
						<b>Xác nhận</b>
					</button>
				)}
				
				<div>

				</div>
				

				{
				toggleLoading
				 && 
				(
					<img src = '/loading.gif' className={style.loading}></img>
				)}
				{!checkPassword && (
					<span className={style.error}>
						Mật khẩu không trùng khớp
					</span>
				)}
            </div>
        </div>
    );
}

export default ChangePassword;
