
import axios from 'axios'
import { url } from './api';
const URL = `${url}/api/auth`;


export const register = (payload) => {
    return axios.post(`${URL}/register`, payload)
}

export const verify = (payload) => {
    return axios.post(`${URL}/register/verify`, payload)
}

export const verifyEmail = (payload) => {
    return axios.post(`${URL}/change-password-otp`, payload)
}

export const verifyChangePasswordOtp = (payload) => {
    return axios.post(`${URL}/verify-change-password-otp`, payload)
}

export const changePassword = (payload) => {
    return axios.put(`${URL}/change-password`, payload)
}


export const loginStart = (user) => axios.post(`${URL}/login`, user)