import React from 'react';

import styles from "./introduction.module.scss"
import Navbar from '../../components/NavBar/NavBar';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Introduction = () => {
    return (
        <div className={styles.container}>
            <Navbar></Navbar>
            <div className = {styles.title}>
                <span>
                    Dịch vụ tư vấn và khuyến nghị của Chartify
                </span>
            </div>
            <div className={styles.body}>
                <div className={styles.text}>
                    <div className = {styles.text1}>
                        <h3>Quyền lợi của khách hàng:</h3>
                        <div className={styles.bullet}>
                            <FiberManualRecordIcon className = {styles.dot}/>  Nhận định thị trường hàng ngày
                        </div>
                        <div className={styles.bullet}>
                            <FiberManualRecordIcon className = {styles.dot}/> Cập nhật và phân tích vĩ mô thường xuyên
                        </div>
                        <div className={styles.bullet}>
                            <FiberManualRecordIcon className = {styles.dot}/> Phân tích những ngành có tiềm năng tăng trưởng tốt trong trương lai 
                        </div>
                        <div className={styles.bullet}>
                            <FiberManualRecordIcon className = {styles.dot}/> Nhân viên tư vấn 1:1
                        </div>
                        <div className={styles.bulletNote}>
                            <FiberManualRecordIcon className = {styles.dot}/> Không cần trả phí chỉ cần mở tài khoản VPS theo link bên dưới
                        </div>
                    </div>


                    <div className= {styles.main}>

                        <div className = {styles.mobileImg}>
                            <img src="/broker.png" alt="" />
                        </div>

                        <div className = {styles.text2}>
                            <h3>Về chúng tôi:</h3>
                            <div className={styles.bullet}>
                                <FiberManualRecordIcon className = {styles.dot}/> Trường phái đầu tư: đầu tư tăng trưởng theo CANSLIM
                            </div>
                            <div className={styles.bullet}>
                                <FiberManualRecordIcon className = {styles.dot}/> Kinh nghiệm đầu tư trên 10 năm
                            </div>
                            <div className={styles.bullet}>
                                <FiberManualRecordIcon className = {styles.dot}/> Phân tích những ngành có tiềm năng tăng trưởng tốt trong trương lai 
                            </div>
                            <div className={styles.bullet}>
                                <FiberManualRecordIcon className = {styles.dot}/> Đội ngũ phân tích tích chuyên nghiệp
                            </div>
                            <div className={styles.bulletLink}>
                                <FiberManualRecordIcon className = {styles.dot}/> Kênh youtube: &thinsp;<a href='https://www.youtube.com/@Chartifyvn' target="_blank">https://www.youtube.com/@Chartifyvn</a>
                            </div>
                            <div className={styles.bulletLink}>
                                <FiberManualRecordIcon className = {styles.dot}/> Kênh TikTok: &thinsp;<a href='https://www.tiktok.com/@chartifyvn' target="_blank">https://www.tiktok.com/@chartifyvn</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = {styles.img}>
                    <img src="/broker.png" alt="" />
                </div>
            </div>

            <div className={styles.contact}>
                <div className={styles.text}>
                    Liên hệ tư vấn
                </div>
                <div className={styles.contactInfo}>
                    <a href='https://openaccount.vps.com.vn/?MKTID=bcfx'>
                        <span>
                            Mở tài khoản chứng khoán tại VPS và nhận được sự tư vấn từ chúng tôi
                        </span>
                    </a>
                </div>

                <div className={styles.contactInfo}>
                    <a href = "https://zalo.me/g/ofozim302">
                        <span>
                            Tham gia nhóm cộng đồng
                        </span>
                    </a>
                </div>

                <div className={styles.contactInfo}>
                    <a href="https://zalo.me/0339570042">
                        <span>
                            Liên hệ cá nhân
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Introduction;



