import React from 'react';
import styles from './notFound.module.scss';

const NotFound = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>404 Not Found</h1>
      <p className={styles.message}>Sorry, the page you are looking for does not exist.</p>
      <a className={styles.link} href="/">Go back to home</a>
    </div>
  );
};

export default NotFound;