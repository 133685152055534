import React, { useEffect, useRef, useState } from "react";

const Axis = () => {
    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);
    const [axisWidth, setAxisWidth] = useState(0);
    const [axisHeight, setAxisHeight] = useState(0);

    const axisRef = useRef(null);

    useEffect(() => {
        const element = axisRef.current;
        const updateSize = () => {
            const newWidth = element.offsetWidth;
            const newHeight = element.offsetHeight;
            if (newWidth !== axisWidth || newHeight !== axisHeight) {
                setAxisWidth(newWidth);
                setAxisHeight(newHeight);
            }
        };
        updateSize();
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
    }, [axisWidth, axisHeight]);


    function handleMouseMove(event) {
        const rect = axisRef.current.getBoundingClientRect();

        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        setMouseX(x);
        setMouseY(y);
    }

    function drawAxis(ctx) {
        // Clear the canvas
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        ctx.strokeStyle = "grey";
        ctx.lineWidth = 1;

        ctx.beginPath();
        ctx.moveTo(0, mouseY);
        ctx.lineTo(mouseX, mouseY);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(mouseX, ctx.canvas.height);
        ctx.lineTo(mouseX, mouseY);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(ctx.canvas.width, mouseY);
        ctx.lineTo(mouseX, mouseY);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(mouseX, 0);
        ctx.lineTo(mouseX, mouseY);
        ctx.stroke();
    }

    const handleMouseLeave = () => {
        const canvas = document.getElementById("canvas");
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    };

    // Use an effect hook to draw the axis on a canvas when the mouse position changes
    useEffect(() => {
        // Get the canvas element and its context
        const canvas = document.getElementById("canvas");
        const ctx = canvas.getContext("2d");

        // Draw the axis on the canvas
        drawAxis(ctx);
    }, [mouseX, mouseY]);

    // Return a JSX element that renders the axis and the canvas and listens to the mouse move and axis load events
    return (
        <canvas
            id="canvas"
            ref={axisRef}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            width={axisWidth}
            height={axisHeight}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: "100",
                cursor: "none"
            }}
        />
    );
};

export default Axis;
