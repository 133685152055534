import React from 'react';

import styles from "./deleteComment.module.scss"
import { useSelector } from 'react-redux';
import { deleteComment } from '../../api/commentAPI';
import { useState } from 'react';

const DeleteComment = ({handleCloseModal, commentId, reload}) => {

    const user = useSelector(state => state.user.user)

    const [deleting, setDeleting] = useState(false)

    const handleDeleteComment = () => {
        deleteComment({
            userId: user._id,
            commentId
        })
        .then(res => {
            handleCloseModal(true)
            setDeleting(false)
            handleCloseModal(false)
            reload(pre => pre + 1)
        })
    }

    return (
        <div className={styles.container}>
            <div className = {styles.modal}>
                <div className = {styles.title}>Xóa bình luận</div>
                <div className = {styles.warning}>
                    Bạn muốn xóa bình luận này?
                </div>
                <div className={styles.action}>
                    <span className = {styles.cancelBtn} onClick = {() => handleCloseModal(false)}>
                        Hủy
                    </span>
                    {
                        deleting 
                        ?
                        <div className = {styles.deleteIconContainer}>
                            <img src="/loading.gif" alt="" />   
                        </div>
                        :
                        <span className={styles.deleteBtn} onClick = {() => {
                            handleDeleteComment()
                            setDeleting(true)
                        }}>
                            Xóa
                        </span>
                    }
                </div>
            </div>

        </div>
    );
}

export default DeleteComment;
