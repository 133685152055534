import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CommentSection from '../../components/CommentSection/CommentSection';
import Navbar from '../../components/NavBar/NavBar';
import styles from './course.module.scss'
import { updateLearningProgressRequest } from '../../redux/actions/user';
import { Helmet } from 'react-helmet';



const Course = () => {
    const user = useSelector(state => state.user.user)
    const courses = useSelector(state => state.courses.courses)
    const param = useParams()
    console.log(param);
    const dispatch = useDispatch()
    const [course, setCourse] = useState(null)

    useEffect(() => {
        if(course && user){
            if(!user.courseRecord.includes(course._id))
                dispatch(updateLearningProgressRequest({
                    userId: user._id,
                    courseId: course._id
                }))
        }
    },[course, user, dispatch])
    
    // useEffect(() => {
    //     if(user){
    //         const userLearningProgress = user.progress
    //         console.log(userLearningProgress);
    //     }
    // }, [param.id, user])


    useEffect(() => {
        if(courses){
            setCourse(courses.find((course) => course._id === param.id))
        }
    },[param.id, courses])

    useEffect(() => {
        document.querySelector('meta[name="description"]').innerText = 'Your page description';
    },[])
    
    if(!course){
        return(
            <Navbar/>
        )
    }

    return (
        <div>
        <div className={styles.container}>
            <Navbar></Navbar>
            <Helmet>
                <title>{course.title}</title>

                <meta property="og:title" content="abcd"/>  
        
            </Helmet>
            <h1>{course.title}</h1>
            <div className={styles.tiny} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(course.content)}}/>
            <div className={styles.commentSection}>
                <CommentSection pageId = {course._id}/>     
            </div>
        </div>
        </div>
    );
}

export default Course;
