import { useEffect, useRef, useState } from "react";
import {useSelector, useDispatch} from "react-redux"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import video from "../../assets/a2.mp4"
import img from "../../assets/img/a2.jpg"


import styles from "./exercise.module.scss";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Navbar from "../../components/NavBar/NavBar";
import { getExercise, submit } from "../../api/exerciseAPI";
import { useParams, useNavigate, Link   } from "react-router-dom";
import Widget from "../../components/Widget/Widget";
import { updatePracticeProcess } from "../../api/userAPI";
import * as updateUserActions  from "../../redux/actions/user"
import DOMPurify from "dompurify";
import CommentSection from "../../components/CommentSection/CommentSection";
import Axis from "../../components/Axis/Axis";
import { getOneCourse } from "../../api/courseAPI";


const Exercise = () => {

    const user =  useSelector( state => state.user.user)
    const exercises =  useSelector( state => state.exercises.exercises)
	const courses = useSelector( state => state.courses.courses)
    const socket =  useSelector( state => state.socket)
	const dispatch = useDispatch()
	const param = useParams()
	const navigate = useNavigate();
	const [exercise, setExercise] = useState(null)
	const [course, setCourse] = useState(null)
	const [exerciseId, setExerciseId] = useState(null)
	const [exceedLimit, setExceedLimit] = useState(false)
	const [exceedLimitNonUser, setExceedLimitNonUser] = useState(false)
	const [exceedBlock, setExceedBlock] = useState(false)
	const [submitRecord, setSubmitRecord] = useState(false)

	const [showed, setShowed] = useState(false);
	const [runInitVideo, setRunInitVideo] = useState(true);
	const [correctAnswer, setCorrectAnswer] =  useState(false)
	const [startChecking, setStartChecking] = useState(false)
	const [pickedAnswer, setPickedAnswer] = useState(0);
	const [showOverviewImage, setShowOverViewImage] = useState(false)
	const [imgLoaded, setImgLoaded] = useState()


	const wrapperRef = useRef(null);
	const initVideoRef = useRef(null)
	const axisContainerRef = useRef(null)

	const htmlInitVideo = exercise && `
		<video src = ${exercise.initVideo} autoPlay muted playsinline ></video>
	`
	const pageRef = useRef(null)


	useEffect(() => {
		if(initVideoRef.current){
			initVideoRef.current.getElementsByTagName('video')[0].addEventListener("ended", (event) => {
				setRunInitVideo(false)
			});
		}
	})

	useEffect(() => {
		setExceedLimit(false)
		setExceedLimitNonUser(false)
		setExceedBlock(false)
	},[])

	useEffect(() => {
		setExceedLimit(false)
		setExceedLimitNonUser(false)
		if(exercises.length > 0){
			
			if(exercises.length > 0 && param.order <= exercises.length){
				setExercise(exercises[param.order-1])
				setExerciseId(exercises[param.order-1]._id)
				setExceedLimit(false)
			}

			if(!user && param.order > 18){
				setExceedLimitNonUser(true)
			}
			
			if(user){ 
				if(param.order > exercises.length){
					setExceedLimit(true)
				}
			}

			if(user){
				if(param.order > user.progress.practice + 1 && param.order <= exercises.length){
					setExceedBlock(true)
				}
			}
		}
	},[param.order,exercises])


	useEffect(() => {
		setImgLoaded(false)
	},[param.order])

	useEffect(() => {
		
		if(exercise){
			if(exercise.initVideo === ""){
				setRunInitVideo(false)
			}else{
				setRunInitVideo(true)
			}
			setPickedAnswer(0)
			setStartChecking(false)
			setShowed(false)
		}
	},[exerciseId])

	useEffect(() => {
		if(exercise){
			if(exercise.courseId){
				getOneCourse(exercise.courseId)
					.then(res => {
						setCourse(res.data)
					})
			}
		}
	},[exercise])

	
	useEffect(() => {
		
		function handleClickOutside(event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setShowOverViewImage(false)
			}
		}
		
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef]);
	
	useEffect(() => {
		setRunInitVideo(true)
	},[])
	

	if(exceedLimit){
		return (
			<div>
				<Navbar />
				<Link to = "/practice">
					
					<div className={styles.limitPageContainer}>
						<div className={styles.text}>
							Bạn đã làm hết toàn bộ câu hỏi, hãy ôn lại các bài đã luyện tập hoặc chờ đợi các bài tập mới sẽ được chúng tôi cập nhật sớm nhất có thể
						</div>
						<span>
							Xem lại toàn bộ câu hỏi
						</span>
					</div>
				</Link>
			</div>
	)}
	if(!exercise){
		return (
			<Navbar />
		)
	}

	if(exceedLimitNonUser){
		return (
			<div>
				<Navbar />
				<Link to = "/practice">
					
					<div className={styles.limitPageContainer}>
						<div className={styles.text}>
							Hãy đăng nhập để mở khóa toàn bộ câu hỏi
						</div>
						<span>
							Xem lại toàn bộ câu hỏi
						</span>
					</div>
				</Link>
			</div>
	)}
	if(!exercise){
		return (
			<Navbar />
		)
	}

	if(exceedBlock){
		return (
			<div>
				<Navbar />
				<Link to = "/practice">
					
					<div className={styles.limitPageContainer}>
						<div className={styles.text}>
							Các bài tập sẽ được mở khóa dần trong quá trình luyện tập của bạn
						</div>
						<span>
							Xem lại toàn bộ câu hỏi
						</span>
					</div>
				</Link>
			</div>
	)}
	if(!exercise){
		return (
			<Navbar />
		)
	}



	const answerList = exercise.answerList
	const answer = exercise.correctAnswer

	const showAnswerStat = () => { 
		let check = 0
		for(let i = 0; i < exercise.firstAttempt.length; i++){
			if(exercise.firstAttempt[i].checkAnswer === true){
				check += 1
			}
		}
		if( exercise.firstAttempt.length === 0) return 0
		return Math.round(check/exercise.firstAttempt.length*100)
	}

	const checkFirstSubmit = () => exercise.firstAttempt.some( e => {
		if (e.userId === user._id) {
			return true;
		  }
		  return false;
	})
	

	const submitAnswer = (check) => {
		setSubmitRecord(true)
		submit({
			exerciseId: exercise._id,
			userId: user._id,
			checkAnswer: check
		})

	}



	const checkAnswer = () => {
		if(pickedAnswer === answer){
			setCorrectAnswer(true)
			if(user){
				if(exercise.order > user.progress.practice){
					setSubmitRecord(true)
					dispatch(updateUserActions.updatePracticeProgressRequest({
						userId : user._id,
						updateData: exercise.order
					}))
				}
				if(!user.practiceRecord.some(ex => ex.exerciseId === exercise._id)){
					setSubmitRecord(true)
					dispatch(updateUserActions.updatePracticeRecordRequest({
						userId : user._id,
						record:{
							exerciseId: exercise._id,
							result:true
						}
					}))
				}
			}
		}
		else{
			setCorrectAnswer(false)
			if(user){
				if(exercise.order > user.progress.practice){
					setSubmitRecord(false)
					dispatch(updateUserActions.updatePracticeProgressRequest({
						userId : user._id,
						updateData: exercise.order
					}))
				}
				if(!user.practiceRecord.some(ex => ex.exerciseId === exercise._id)){
					setSubmitRecord(false)
					dispatch(updateUserActions.updatePracticeRecordRequest({
						userId : user._id,
						record:{
							exerciseId: exercise._id,
							result:false
						}
					}))
				}
			}
		}
	}


	const nextPage = () => {
		setExercise(null)
		navigate(`/practice/exercise/${(parseInt(param.order) + 1).toString()}`)
		socket.emit("dm", "dmmmmmmmmmmmmmmmm")
	}
	const previousPage = () => {
		setExercise(null)
		navigate(`/practice/exercise/${(parseInt(param.order) - 1).toString()}`)
	}


 	return (
    <div className={styles.container} ref = {pageRef}>
        <Navbar/>

		{
			showOverviewImage 
			&&
			<div className={styles.blurBackground}>
				<div className= {styles.overviewWrapper}>
					<img className={styles.overviewImage} src = {exercise.overviewImg} ref={wrapperRef}></img>
				</div>
			</div>
		}

		<div className={styles.main}>

			<div className={styles.exerciseContainer}>

				<div className={styles.exercise}>
				
					<div className={styles.questionContainer}>
						<div className={styles.question}>
							<span className={styles.text}>{exercise.question}</span>
							<span className={styles.stat}>Tỷ lệ trả lời đúng câu hỏi:&thinsp;<span className={styles.p}>{showAnswerStat()}%</span></span>
						</div>
						<div className={styles.display} ref = {axisContainerRef}>
							<Axis/>
							{
								!imgLoaded
								&&
								<div className={styles.holder}>

								</div>
							}
							
							{ 
								(exercise.initImg !== "" && runInitVideo)
								&&
								<img className = {styles.initImg} src={exercise.initImg} alt="" />
							}
							{
								runInitVideo && exercise.initVideo !== "" 
								&&
								<div className={styles.initChart}>
									<div ref = {initVideoRef} className={styles.tiny} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(htmlInitVideo)}}/>
								</div>
								
							}
		
							<img className = {imgLoaded ? styles.mainImg : styles.mainImgLoading} src={exercise.imageURL[0]} alt="" onLoad={() => {
								setImgLoaded(true)
							}}/>																																		
							
							{
								!showed || <video src={exercise.videoURL[0]} autoPlay muted="true" playsInline  onEnded={() => {
									checkAnswer()
									setStartChecking(true)
								}}></video>
							}
							{
								(startChecking && !correctAnswer) ? 
							
									<CloseIcon className= {styles.checkIconFalse}></CloseIcon>
							
								: <div></div>
							}
							{
								(startChecking && correctAnswer) ? 
							
									<DoneIcon className= {styles.checkIconTrue}></DoneIcon>
							
								: <div></div>
							}						

						</div>
						
					</div>


					<div className={styles.showContainer}>
						{
							runInitVideo 
							?
							answerList.map((answer, i) => (
								<div className={styles.answerDisabled} >
									<span>{answer}</span>
								</div>
							))
							:
								pickedAnswer === 0 
								?
								answerList.map((answer, i) => (
									<div className={styles.answer} 
									onClick={() => {
										// if(i+1 === exercise.correctAnswer){
										// 	handleSubmit(true)
										// }else{
										// 	handleSubmit(false)
										// }
										if(user){
											if(!checkFirstSubmit()){
												if(i+1 === exercise.correctAnswer){
													submitAnswer(true)
												}else{
													submitAnswer(false)
												}
											}
										}
										setShowed(true)
										setPickedAnswer(i+1);



									}}>
										<span>{answer}</span>
									</div>
								))
								:						
								answerList.map((answer, i) => (
									<div className={(pickedAnswer === i + 1)? styles.answerPicked : styles.answerDisabled} >
										<span>{answer}</span>
									</div>
								))
							
						}
					</div>

				</div>

				<div className={styles.controller}>
					{
						pickedAnswer !== 0  ?
						<>
							<button className={styles.reset} 
								onClick={() => {
									setShowed(false)
									setStartChecking(false)
									setPickedAnswer(0)
									if(exercise.initVideo !== ""){
										setRunInitVideo(true)
									}
								}
								}>
								Hiển thị lại câu hỏi
							</button>
							{
								startChecking	
								?
								<button className={styles.next} onClick = {() => {
									nextPage()
									setStartChecking(false)
								}}>
									Chuyển đến câu tiếp theo
								</button>
								:
								<button className={styles.nextDisabled} >
									Chuyển đến câu tiếp theo
								</button>
							}
						</>
						: 
						<>
							{
								param.order > 1
								?
								<button className={styles.next} onClick = {previousPage} >
									Quay lại câu hỏi trước
								</button>
								:
								<button  className={styles.disabled} >
									Quay lại câu hỏi trước
								</button>				
							}
							{
								!runInitVideo
								&&
								<button className={styles.next} onClick={() => setShowOverViewImage(true)}>
									Xem biểu đồ tổng quát
								</button>
							}
						</>
					}
				</div>
				
				<Link to = '/practice' >    
					<span className = {styles.showAllEx}>
						Hiển thị toàn bộ câu hỏi		
					</span>
				</Link>

				{
					pickedAnswer !== 0 && startChecking
					?	
					<div>
						<div className={styles.explaination}>
							<span>Giải thích</span>
							<div  className={styles.tiny} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(exercise.explaination)}}/>
							{
								course 
								&&
								<Link to = {"/courses/" + course._id}>
									<div className={styles.course}>
										<img src = {course.thumnailImg}></img>
										<div className = {styles.courseDetail}>
											<div className={styles.courseTitleWrapper}>
												<span className={styles.courseTitle}>
													{course.title}
												</span>
											</div>
											<div className = {styles.des}>
												{course.des}
											</div>
										</div>
									</div>								
								</Link>
							}
						</div>
					</div>
					: 
					<></>
				}

				<div className={styles.mobileWidgetContainer}>
					<div className={styles.mobileWidget}>
						<Widget />
					</div>
				</div>

				<div>
					<CommentSection pageId = {exercise._id}/>
				</div>

			</div>
			<div className={styles.widgetContainer}>
				<div className={styles.widget}>
					<Widget />
				</div>
			</div>
		
		</div>

    </div>

  );
};

export default Exercise;
